import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HeaderNav from "./components/HeaderNav/HeaderNav";
import Footer from "./components/Footer/Footer";
import { lazy, Suspense } from "react";
import Home from "./pages/home/Home";
import { ToastContainer } from "react-toastify";
import HashLinkObserver from "react-hash-link";
import Loader from "./components/Loader/Loader";
const Services = lazy(() => import("./pages/services/Services"));
const Contact = lazy(() => import("./pages/contact/Contact"));

function App() {
  return (
    <>
      <HeaderNav />

      <Router>
        <ToastContainer position="bottom-center" limit={1} />

        <Routes>
          <Route
            path="/"
            element={
              <Suspense fallback={<Loader />}>
                <Home />
              </Suspense>
            }
          />
          <Route
            path="/nos-services"
            element={
              <Suspense fallback={<Loader />}>
                <Services />
              </Suspense>
            }
          />
          <Route
            path="/contact"
            element={
              <Suspense fallback={<Loader />}>
                <Contact />
              </Suspense>
            }
          />
        </Routes>
      </Router>
      <Footer />
    </>
  );
}

export default App;
