import { Link } from "react-router-dom";
import "./home.scss";
import { Helmet } from "react-helmet-async";
import { lazy } from "react";
const TestimonialCarousel = lazy(() =>
  import("../../components/TestimonialCarousel")
);
const EmailSender = lazy(() => import("../../components/Email/EmailSender"));

const Home = () => {
  return (
    <>
      <Helmet>
        <title>
          GSP - Service de Nettoyage Professionnel à Montréal, Rive-Sud
        </title>

        <link rel="canonical" href="https://gsp-nettoyage.com/" />
        <meta
          name="description"
          data-rh="true"
          content="GSP, fondée en 2019, offre des services de nettoyage professionnels adaptés aux besoins de chaque client. Nous garantissons des résultats impeccables grâce à des méthodes personnalisées, des produits écologiques et une équipe formée. Que ce soit pour un entretien régulier, un nettoyage en profondeur ou un service après déménagement, nous transformons chaque espace en un lieu accueillant. Rejoignez-nous pour un environnement propre et sain."
        />
      </Helmet>
      <section className="hero-section">
        <div className="hero-content">
          <h1>
            Votre partenaire en entretien ménager, résidentiel & commercial
          </h1>
          <p className="w-100 w-md-50">
            Chez GSP services d’entretien ménager, nous croyons que chaque
            espace mérite d’être propre, sain et accueillant.
          </p>
          <a className="btn btn-primary" href="contact">
            <img src="icon/calendar.svg" className="btn-icon" alt="" />
            Réservation en ligne
          </a>
        </div>
      </section>
      <section className="story-section">
        <div className="row align-items-center justify-content-center gap-3 gap-md-0">
          <div className="col-md-6 col-12 h-100 p-0 pe-md-3 pe-lg-5">
            <img src="img/story-section_image.jpg" alt="Image Histoire" />
          </div>
          <div className="col-md-6 col-12  p-0 ps-md-3 ps-lg-5">
            <div className=" ps-0 ps-lg-5 d-flex flex-column gap-2 gap-md-4">
              <h3>Notre Histoire</h3>
              <p>
                Fondée en 2019  par une équipe passionnée de professionnels du
                ménage, GSP a vu le jour avec une mission claire :{" "}
                <strong>
                  offrir un service de nettoyage exceptionnel qui dépasse les
                  attentes de ses clients.
                </strong>{" "}
                <br /> <br />
                Dès le début, nous avons compris que chaque espace est unique.
                C'est pourquoi nous avons développé des{" "}
                <strong>
                  méthodes de nettoyage personnalisées, adaptées aux besoins
                  spécifiques de chaque client.
                </strong>{" "}
                Que ce soit pour un entretien régulier, un nettoyage en
                profondeur ou un service après déménagement, notre équipe
                s'engage à utiliser des produits écologiques et des techniques
                efficaces pour garantir des résultats impeccables.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="services-section d-flex flex-column gap-5">
        <h3>Découvrez nos services</h3>
        <div className="row gap-4">
          <div className="card card-service col-12 col-lg p-0">
            <img
              src="img/house_cleaning_image.jpg"
              class="card-img-top"
              alt="..."
            />
            <div className="card-body p-3">
              <h6 className="card-title">Entretien ménager résidentiel</h6>
              <p className="card-text small mb-3">
                Un nettoyage approfondi de votre maison pour un cadre
                accueillant et propre.
              </p>
              <Link
                to="/nos-services#entretien-residentiel"
                className="btn btn-secondary"
              >
                En savoir plus
              </Link>
            </div>
          </div>
          <div className="card card-service col-12 col-lg p-0 ">
            <img
              src="img/office_cleaning_image.jpg"
              class="card-img-top"
              alt="..."
            />
            <div className="card-body p-3">
              <h6 className="card-title">Entretien ménager commercial</h6>
              <p className="card-text small mb-3">
                Un entretien commercial adapté pour un environnement de travail
                propre.
              </p>
              <Link
                to="/nos-services#entretien-menager"
                className="btn btn-secondary"
              >
                En savoir plus
              </Link>
            </div>
          </div>
          <div className="card  card-service col-12 col-lg p-0">
            <img
              src="img/party-cleaning_image.jpg"
              class="card-img-top"
              alt="..."
            />
            <div className="card-body p-3">
              <h6 className="card-title">Entretien après événementiel</h6>
              <p className="card-text small mb-3">
                Un nettoyage professionnel après vos événements pour un espace
                propre.
              </p>
              <Link
                to="/nos-services#evenement-nettoyage"
                className="btn btn-secondary"
              >
                En savoir plus
              </Link>
            </div>
          </div>
        </div>
      </section>
      <section className="makes-us-special-section">
        <div className="row align-items-center justify-content-center gap-3 gap-md-0">
          <div className="col-md-6 col-12 pe-0 pe-md-3 pe-lg-5 order-1 order-md-0">
            <div className="pe-0 pe-md-3 pe-lg-5 d-flex flex-column gap-3  gap-md-5">
              <h3>Ce qui nous rend spécial</h3>
              <p>
                Chez GSP, notre engagement envers l'excellence se reflète dans
                chaque aspect de notre service. Nous mettons un point d’honneur
                à offrir une expérience de nettoyage unique, adaptée à vos
                besoins. Voici ce qui nous distingue :
              </p>
              <div className="d-flex flex-column advantages gap-3 gap-md-0">
                <div className="d-flex advantage gap-2 gap-lg-4">
                  <img src="icon/checkmark.svg" className="icon" alt="" />
                  <p>Satisfaction garantie à chaque prestation</p>
                </div>
                <div className="d-flex advantage gap-2 gap-lg-4">
                  <img src="icon/checkmark.svg" className="icon" alt="" />
                  <p>Prix transparents sans frais cachés</p>
                </div>
                <div className="d-flex advantage gap-2 gap-lg-4">
                  <img src="icon/checkmark.svg" className="icon" alt="" />
                  <p>Équipe professionnelle et expérimentée</p>
                </div>
                <div className="d-flex advantage gap-2 gap-lg-4">
                  <img src="icon/checkmark.svg" className="icon" alt="" />
                  <p>Nettoyage sur mesure adapté à vos besoins</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-12 h-100 p-0 ps-md-3 ps-lg-5">
            <img src="img/special-section_image.jpg" alt="Image Histoire" />
          </div>
        </div>
      </section>
      <section className="benefices-section flex-column">
        <h3>Pourquoi GSP?</h3>
        <div className="benefices">
          <div className="benefice">
            <div className="icon-container">
              <img src="icon/tools.svg" alt="" />
            </div>
            <div className="benefice-text px-3">
              <h6>Équipement spécialisé</h6>
              <p className="small">
                Nous avons accès à des équipements et produits de nettoyage de
                haute qualité, souvent plus performants que ceux du commerce.
              </p>
            </div>
          </div>
          <div className="benefice">
            <div className="icon-container">
              <img src="icon/clock.svg" alt="" />
            </div>
            <div className="benefice-text px-3">
              <h6>Flexibilité</h6>
              <p className="small">
                Nous offrons des services sur mesure adaptés aux besoins
                spécifiques des clients que ce soit pour un nettoyage régulier
                ou ponctuel.
                <br />
                <br />
                <strong>Disponibilité : 7/7 jours.</strong>
              </p>
            </div>
          </div>
          <div className="benefice">
            <div className="icon-container">
              <img src="icon/plant.svg" alt="" />
            </div>
            <div className="benefice-text px-3">
              <h6>Respect de l’environnement</h6>
              <p className="small">
                Notre entreprise de ménage s’engage à utiliser des produits
                écologiques pour préserver votre santé et celle de la planète.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="steps-section d-flex gap-5 flex-column">
        <h3>Comment ça fonctionne?</h3>
        <div className="d-flex flex-column gap-5">
          <div className="step-card">
            <div className="step-img">
              <img src="img/step-one_image.jpg" alt="" />
            </div>
            <div className="step-content p-4 d-flex flex-column gap-2">
              <div className="step-title">
                <h6>Réservez en Ligne en Toute Simplicité</h6>
              </div>
              <div className="step-text">
                <p className="small">
                  Envoyez-nous votre demande de réservation facilement et nous
                  vous contacterons pour finaliser les détails.
                </p>
              </div>
            </div>
          </div>
          <div className="step-card">
            <div className="step-img">
              <img src="img/step-two_image.jpg" alt="" />
            </div>
            <div className="step-content p-4 d-flex flex-column gap-2">
              <div className="step-title">
                <h6>C’est Fait !</h6>
              </div>
              <div className="step-text">
                <p className="small">
                  Nous vous rappellerons dans les plus brefs délais pour
                  confirmer et organiser votre service.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <TestimonialCarousel />
      <section className="reserve-section py-5">
        <div className="row gap-3 align-items-center">
          <div className="col-md-6 col-lg-7 col-12">
            <h3>Réservez votre service de nettoyage dès aujourd'hui !</h3>
          </div>
          <div className="col p-0 px-md-2 py-5">
            <EmailSender />
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;
