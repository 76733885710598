import MobileMenu from "../MobileMenu/MobileMenu";
import "./headernav.scss";

const HeaderNav = () => {
  return (
    <header>
      <nav className="d-flex justify-content-between align-items-center w-100 h-100 py-1 py-md-2">
        <div className="d-flex h-100 align-items-center gap-2">
        <a href="/" className="logo">
          <img src="/img/logo.png" alt="logo" />
        </a>
        <div>
        
        </div>
        </div>
       

        <div className="nav-content-desktop d-none d-md-flex  gap-5 align-items-center">
          <div className="nav-item">
            <a href="/">Accueil</a>
          </div>
          <div className="nav-item">
            <a href="/nos-services">Services</a>
          </div>
          <div className="nav-item">
            <a className="btn btn-primary btn-nav" href="/contact">
              <img
                src="/icon/mail-contact.svg"
                alt="contact-icon"
                className="btn-icon"
              />
              Contact
            </a>
          </div>
        </div>
        <div className="nav-content-desktop d-inline-block d-md-none ">
          <MobileMenu />
        </div>
      </nav>
    </header>
  );
};

export default HeaderNav;
