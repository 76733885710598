import "./Footer.scss";

const Footer = () => {
  return (
    <footer className="footer-section pt-5">
      <div>
        <div className="footer-cta pt-5 pb-5">
          <div className="row align-items-center gap-4">
            <div className="col-xl-4 col-md-4 mb-30 d-flex gap-3 align-items-center">
              <img src="icon/phone.svg" className="icon" alt="" />

              <div className="single-cta">
                <div className="cta-text">
                  <h4>Appellez-nous</h4>
                  <span>
                    <a href="tel:+15144516258">(514) 451-6258</a>
                  </span>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-4 mb-30 d-flex gap-3 align-items-center">
              <img src="icon/mail.svg" className="icon" alt="" />

              <div className="single-cta">
                <div className="cta-text">
                  <h4>Contactez-Nous</h4>
                  <span>
                    <a href="mailto:info@gsp-nettoyage.com?subject=Question%20pour%20GSP&body=Bonjour,%20j'aimerais%20poser%20une%20question%20concernant%20vos%20services,">
                      info@gsp-nettoyage.com
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-content pt-5 pb-5">
          <div className="row justify-content-between gap-5">
            <div className="col-xl-4 col-lg-4 mb-50">
              <div className="footer-widget">
                <div className="footer-logo">
                  <a href="index.html">
                    <img src="img/logo.png" className="img-fluid" alt="logo" />
                  </a>
                </div>
                <div className="footer-text">
                  <p className="small">
                    GSP est une entreprise de nettoyage professionnel servant
                    Montréal et la Rive-Sud, spécialisée dans des services de
                    qualité supérieure pour des environnements résidentiels et
                    commerciaux.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6 mb-30">
              <div className="footer-widget">
                <div className="footer-widget-heading">
                  <h3>Navigation</h3>
                </div>
                <ul>
                  <li>
                    <a href="/">Accueil</a>
                  </li>
                  <li>
                    <a href="/contact">Contact</a>
                  </li>
                  <li>
                    <a href="/nos-services">Nos services</a>
                  </li>
                  <li></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright-area">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center text-lg-left">
              <div className="copyright-text">
                <p>Copyright &copy; 2024, All Right Reserved </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
